<template>
  <el-dialog
    append-to-body
    :title="title"
    :visible.sync="showFlag"
    :destroy-on-close="true"
    width="86.25rem"
    class="template-public-dialog check-log-dialog"
    @open="initData"
    top="10vh"
  >
    <div class="check-log-container">
      <div class="tab-container">
        <div 
          v-for="(tab, index) in tabArr"
          :key="index"
          :class="curTab === tab.type ? 'active' : ''"
          @click="toggleTab(tab)"
        >{{tab.label}}</div>
      </div>
      <div class="content">
        <login-log v-if="'log' === curTab"></login-log>
        <personnel-management-log 
          v-if="'personnelManagement' === curTab"
        ></personnel-management-log>
        <project-organization-log 
          v-if="'projectOrganization' === curTab"
        ></project-organization-log>
        <authority-management-log 
          v-if="'authorityManagement' === curTab"
        ></authority-management-log>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import LoginLog from './LoginLog'
import PersonnelManagementLog from './PersonnelManagementLog'
import ProjectOrganizationLog from './ProjectOrganizationLog'
import AuthorityManagementLog from './AuthorityManagementLog'
export default {
  props: {},
  watch: {},
  data() {
    return {
      title: '操作日志',
      showFlag: false,
      curTab: 'log',
      tabArr: [
        {
          label: '登录日志',
          type: 'log'
        },
        {
          label: '人员管理操作日志',
          type: 'personnelManagement'
        },
        {
          label: '项目组织操作日志',
          type: 'projectOrganization'
        },
        {
          label: '权限管理操作日志',
          type: 'authorityManagement'
        }
      ]
    }
  },
  computed: {},
  components: {
    LoginLog,
    PersonnelManagementLog,
    ProjectOrganizationLog,
    AuthorityManagementLog
  },
  methods: {
    initData() {},
    toggleTab(tab) {
      this.curTab = tab.type
    }
  },
  created() {

  },
  mounted() {

  },
  beforeDestroy() {

  }
}
</script>

<style lang="scss" scoped>
.check-log-container {
  height: 50.5rem;
  padding: 20px;
  background: #22242C;
  .tab-container {
    height: 3.125rem;
    line-height: 3.125rem;
    display: flex;
    color: #CDCDCD;
    background: #25272E;
    &>div {
      width: 13.125rem;
      text-align: center;
      cursor: pointer;
    }
    .active {
      color: #007AFF;
      background: #2F3139;
    }
  }
  .content {
    height: calc(100% - 3.125rem);
  }
}
</style>
<style lang="scss">
.check-log-dialog {
  margin-left: 11.25rem !important;
}
.check-log-container {
  .content {
    &>div {
      padding-top: 30px;
      height: 100%;
      background: wheat;
      .search-row {
        
      }
    }
  }
}
</style>
