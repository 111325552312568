<!--
 * @Author: wuruoyu
 * @Description: 操作树menu
 * @Date: 2020-12-26 10:36:47
 * @LastEditors: wuruoyu
 * @LastEditTime: 2021-01-13 17:13:05
 * @FilePath: \huayun_platform\src\components\tree\TreeMenu.vue
-->
<template>
  <span class="tree-menu" @click.stop="menuToggle">
    <ul v-show="menushow">
      <li @click.stop="addUnit">
        <i class="el-icon-circle-plus"></i>
        <span class="label">新建子单元</span>
      </li>
      <li @click.stop="delUnit" v-show="!disDel">
        <i class="el-icon-error"></i>
        <span class="label">删除本单元</span>
      </li>
      <li @click.stop="addPerson">
        <i class="el-icon-s-custom"></i>
        <span class="label">新建管理角色</span>
      </li>
    </ul>
  </span>
</template>

<script>
export default {
  data() {
    return {
      menushow:false
    }
  },
  props:{
    disDel:{
      type:Boolean,
      default:() => false
    }
  },
  methods:{
    menuToggle(){
      this.menushow = !this.menushow;
    },
    menuFade(){
      this.menushow = false;
    },
    addUnit(){
      this.$emit('addUnit');
      this.menushow = false;
    },
    delUnit(){
      this.$emit('delUnit');
      this.menushow = false;
    },
    addPerson(){
      this.$emit('addPerson');
      this.menushow = false;
    }
  },
  mounted(){
    // document.documentElement.onclick = () => { this.menushow = false;}
  }
}
</script>

<style lang="scss" scoped>
  .tree-menu{
    display: flex;
    justify-content: center;
    width: 0.8rem;
    height: 0.8rem;
    background: #007AFF;
    border-radius: 50%;
    margin-left: 0.8rem;
    cursor: pointer;
    position: relative;
    &::before{
      position: absolute;
      top: -1.5px;
      left: 2px;
      content: '^';
      font-size: 0.6rem;
      color: rgb(34, 31, 31);
      font-weight: bold;
      transform: rotate(180deg);

    }
    ul{
      margin: 0;
      padding: 0;
      position: absolute;
      top: 1.2rem;
      left: -0.8rem;
      z-index: 10;
    }
    li{
      list-style: none;
      width: 10rem;
      height: 2rem;
      display: flex;
      align-items: center;
      background: #108CEE;
      color: #fff;
      font-size: 1rem;
      border-radius: 4px;
      margin-top: 0.3rem;
      i{
        margin: 0 0.8rem;
        font-size: 1.25rem;
      }
      &:hover{
        background: #0774c7;
      }
    }
  }
</style>
