/*
 * @Author: wuruoyu
 * @Description: axios配置
 * @Date: 2020-05-29 14:09:32
 * @LastEditors: year
 * @LastEditTime: 2021-01-09 10:39:12
 * @FilePath: \huayun_platform\src\api\request.js
 */
import axios from "axios"
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { Message } from 'element-ui'
import { getCookie } from "@/utils/public.js"

NProgress.configure({
  showSpinner: true
});
// create an axios instance
const service = axios.create({
  baseURL: '/', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000 // request timeout
})

service.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8"; //设置编码

//请求拦截
service.interceptors.request.use(
  config => {
    NProgress.start()
    if (config.url.includes('/plat')) {
      let cookieStr = getCookie('userInfo')
      if (cookieStr) {
        let cookieInfo = JSON.parse(cookieStr)
        config.headers.Authorization = cookieInfo.mark
      }
    }
    return config;
  },
  error => {
    NProgress.done()
    return Promise.reject(error);
  }
)
//响应拦截
service.interceptors.response.use(
  response => {
    return new Promise(
      (resolve, reject) => {
        NProgress.done()
        const res = response.data;
        if (res.status === "SUCCESS") {
          resolve(res);
        }else if(res.status === "200"){
          resolve(res);
        } else {
          Message({
            type: 'error',
            message:  "操作失败,"+res.message || '请求失败'
          })
          reject(res);
        }
      }
    )
  },
  error => {
    NProgress.done()
    //断网处理或者请求超时
    if (!error.response) {
      //请求超时
      if (error.message.includes("timeout")) {
        Message({
          type: 'error',
          message: "请求超时，请检查互联网连接"
        })
      } else {
        //断网，可以展示断网组件
        Message({
          type: 'error',
          message: "请检查网络是否已连接"
        })
      }
      return;
    }
    const status = error.response.status;
    switch (status) {
      case 500:
        Message({
          type: 'error',
          message: "操作失败"
        })
        break;
      case 404:
        Message({
          type: 'error',
          message: "未找到远程服务器"
        })
        break;
      default:
        Message({
          type: 'error',
          message: "请求失败"
        })
    }
    return Promise.reject(error);
  }
)

export function get(url, params, config) {
  return service.get(url, {
    params,
    config
  })
}

export function post(url, params) {
  return service.post(url, params)
}

export function deletefn(url, params) {
  return service.delete(url, params)
}

export function filePost(url, data) {
  return service.post(url, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
