// 获取cookie
export const getCookie = (cookieName) => {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(cookieName + "=")
    if (c_start != -1) {
      c_start = c_start + cookieName.length + 1
      let c_end = document.cookie.indexOf(";", c_start)
      if (c_end == -1) {
        c_end = document.cookie.length
      }
      return unescape(document.cookie.substring(c_start, c_end))
    }
  }
  return ""
}

// 设置cookie
export const setCookie = (cookieName, value, expiredays) => {
  var exdate = new Date()
  exdate.setDate(exdate.getDate() + expiredays)
  document.cookie =
    cookieName +
    "=" +
    escape(value) +
    (expiredays == null ? "" : ";expires=" + exdate.toGMTString())
}

// 获取父窗口的url
export const getParentUrl = () => {
  let url = null
  if (parent !== window) {
    try {
      url = parent.location.href
    } catch (e) {
      url = document.referrer
    }
  }
  return url
}

export const isSdSys = () => {
  const sysUrl = getParentUrl()
  if (sysUrl && sysUrl.includes('sd.hyhncloud')) {
    return true
  }
  return false
}
