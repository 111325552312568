<template>
  <div>
    <div class="search-row"></div>
  </div>
</template>

<script>
export default {
  props: {},
  watch: {},
  data() {
    return {

    }
  },
  computed: {},
  components: {

  },
  methods: {

  },
  created() {

  },
  mounted() {

  },
  beforeDestroy() {

  }
}
</script>

<style lang="scss" scoped>
.login-log-container {
  background: red;
}
</style>
