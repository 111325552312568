<template>
  <div class="business-panel-container">
    <div class="title">{{business.name}}</div>
    <div class="list-container">
      <div
        v-for="(project, index) in business.sonList"
        :key="index"
        @click="goToPro(project)"
      >
        <span class="bus-icon">
          <i :class="'iconfont ' + project.icon"></i>
        </span>
        <span class="text" :title="project.name">{{project.name}}</span>
      </div>
    </div>
    <little-dialog ref="appDialog" :title="dialogInfo.name">
      <content-qr  v-if="dialogInfo.name=='售电小程序'" :qrSrc="dialogInfo.qrCode">扫描二维码，打开微信小程序</content-qr>
      <content-qr  v-if="dialogInfo.name!='售电小程序'" :qrSrc="dialogInfo.qrCode">扫描二维码，下载安装APP</content-qr>
    </little-dialog>
  </div>
</template>

<script>
import LittleDialog from "@/components/LittleDialog"
import ContentQr from "@/components/ContentQr"
export default {
  props: ['business'],
  data() {
    return {
      dialogInfo: {}
    }
  },
  methods: {
    goToPro(project) {
      if(project.port) {
        project.type="router"
        // let fullPath = window.location.href
        // let s = fullPath.indexOf("//")
        // let e = fullPath.indexOf(":", s)
        // window.location.href = "http://" + project.port
        // window.location.href = project.port
        window.parent.postMessage(project, '*')
      } else if(project.qrCode) {
        let { name, qrCode } = project
        this.dialogInfo = { name, qrCode }
        this.$refs.appDialog.dialogVisible = true
      }
    }
  },
  components: {
    LittleDialog,
    ContentQr
  }
}
</script>

<style lang="scss" scoped>
.business-panel-container {
  width: 100%;
  padding: 1.25rem 1rem 0;
  .title {
    text-align: left;
    border-left: 4px solid #007AFF;
    padding-left: 1rem;
    font-size: 1rem;
    font-weight: 700;
  }
  .list-container {
    padding-top: 1.25rem;
    display: flex;
    flex-wrap: wrap;
    &>div {
      background: #2C2E36;
      height: 5rem;
      border-radius: 4px;
      margin-bottom: 2.5rem;
      margin-right: 2.5rem;
      font-size: 0.875rem;
      display: flex;
      align-items: center;
      padding-left: 1.875rem;
      cursor: pointer;
      &:nth-child(4n+4) {
        margin-right: 0;
      }
      &:hover {
        background: #25272F;
      }
      .bus-icon {
        i {
          font-size: 2.25rem;
          color: #007AFF;
        }
      }
      .text {
        margin-left: 1rem;
      }
      @media screen and (max-width: 1920px) {
        width: 15rem;
      }
      @media screen and (max-width: 1440px) {
        width: 11.8rem;
      }
      @media screen and (max-width: 1366px) {
        width: 13rem;
      }
    }
  }
}
</style>
